import { Card, Typography } from '@mui/material';
import BabyVaccinationSvg from 'assets/images/BabyVaccinationSvg';
import MenstrualSvg from 'assets/images/MenstrualSvg';
import DoctorPrescriptionSvg from 'assets/images/DoctorPrescriptionSvg';
import HealthVitalSvg from 'assets/images/HealthVItalSvg';
import HealthHistorySvg from 'assets/images/HealthHistorySvg';
import LabScanSvg from 'assets/images/LabScanSvg';
import RightArrowSvg from 'assets/images/icons/RightArrowSvg';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useSelector } from 'react-redux';
import useExtractRawQueryParams from 'hooks/useExtractQueryParams';
import NameTile from 'ui-component/NameTile';
import Logo from 'ui-component/Logo';
import { borderColor, height } from '@mui/system';
import PrescriptionsSvg from 'assets/images/PrescriptionsSvg';

const items = [
  {
    label: "Dr Prescriptions",
    value: 'drp',
    uploadType: "DRP",
    route: '/hlog/pdf',
    icon: PrescriptionsSvg,
    reportKey: "drPrescriptionStatus"
  },
  {
    label: "Lab Reports",
    value: 'lab-scan',
    route: '/hlog/labScanReports',
    icon: LabScanSvg,
    reportKey: "labScanStatus"
  },
  {
    label: "Health Vitals",
    value: 'health-vitals',
    route: '/hlog/view-vitals',
    icon: HealthVitalSvg,
    reportKey: "healthVitalsStatus"
  },
  {
    label: "Health History",
    value: 'health-history',
    route: '/hlog/health-history',
    icon: HealthHistorySvg,
    reportKey: "healthHistoryStatus"
  },
  {
    label: "Baby Vaccination Record",
    value: 'bv',
    uploadType: "BVR",
    route: '/hlog/pdf',
    icon: BabyVaccinationSvg,
    reportKey: "babyVaccinationStatus"
  },
];

const ReportCard = ({ title, Icon, disabled, onClick }) => {
  const theme = useTheme();
  const styles = {
    reportCard: {
      border: '1px solid #49C1C3',
      borderRadius: '5px',
      margin: '5px 0',
      padding: '5px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      backgroundColor: disabled ? '#41586E' : theme.palette.background.paper,
      transition: 'background-color 0.3s ease',
    },
  };

  return (
    <Card style={{
      ...styles.reportCard,
      backgroundColor: disabled ? '#41586E' : theme.paper,
      opacity: disabled ? 0.4 : 1
    }}
      onClick={() => !disabled && onClick()}>
      <Icon disabled={disabled} />
      <Typography variant="h3" color='white' fontWeight='bold' textAlign='left' flex={1} marginLeft={2}>{title}</Typography>
      <RightArrowSvg disabled={disabled} />
    </Card>
  );
};

const PatientDashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const { encryptedValue, key } = useExtractRawQueryParams();

  const [options, setOptions] = useState(items);

  const styles = {
    container: {
      color: '#FFFFFF',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: "20px",
      backgroundColor: "#2c3e50",
      minHeight: '100vh',
    },
    header: {
      textAlign: 'center',
      width: '100%',
      marginTop: '5px',
    },
    reportCards: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      flex: 1,
    },
  };

  useEffect(() => {
    if (customization?.userData?.gender === "F" && customization?.userData?.age >= 10) {
      setOptions(prevOptions => {
        if (!prevOptions.find(option => option.value === 'menstrual-cycle')) {
          const menstrualCycleOption = {
            label: "Menstrual Cycle",
            value: 'menstrual-cycle',
            route: '/hlog/menstrual',
            icon: MenstrualSvg,
            reportKey: "menstrualCycleStatus"
          };

          const updatedOptions = [
            ...prevOptions.slice(0, 2), 
            menstrualCycleOption, 
            ...prevOptions.slice(2)
          ];

          return updatedOptions;
        }
        return prevOptions;
      });
    }
  }, [customization]);

  const navigateTo = async (item) => {
    try {
      if (item.route === '/hlog/landing') {
        navigate(`/hlog/landing${window.location.search}`);
      } else {
        navigate(`${item.route}${window.location.search}`, { state: { url: `${process.env.REACT_APP_BASEURL}/report/getreports/?encryptedValue=${encryptedValue}&uid=${key}&reportType=${item.value}&uploadType=${item.uploadType}`, name: item.label } });
      }
    } catch (e) {
      alert('Something went wrong! Please retry.');
    }
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <Logo />
        <Typography variant="h3" color='white' fontWeight='bold'>View Health Info</Typography>
      </header>
      <NameTile />
      <div style={styles.reportCards}>
        {options?.map((option, idx) =>
          <ReportCard
            key={option.value + idx}  // Use both value and index to ensure unique keys
            title={option.label}
            Icon={option.icon}
            disabled={customization.userData[option.reportKey] === "not-avaialble"}
            onClick={() => navigateTo(option)}
          />
        )}
      </div>
    </div>
  );
};

export default PatientDashboard;
