import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {ThemeProvider} from "@mui/material/styles";
import {CssBaseline, StyledEngineProvider} from "@mui/material";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// routing
import Routes from "routes";

// defaultTheme
import themes from "themes";

// project imports
import NavigationScroll from "layout/NavigationScroll";
import {SET_USER_NAME} from "store/actions";
import {fetchPersonDetails} from "services/Api";

// ==============================|| APP ||============================== //

import InvalidLinkMessage from "ui-component/InvalidLinkMessage";
import LoadingSpinner from "ui-component/spinners/LoadingSpinner";
import useExtractQueryParams from "hooks/useExtractQueryParams";

const App = () => {
  const customization = useSelector((state) => state.customization);
  const dispatch = useDispatch();

  const {encryptedValue, key} = useExtractQueryParams();
  const [isLoading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    fetchPersonDetails(encryptedValue, key).then(result => {
      dispatch({type: SET_USER_NAME, data: result});
    }).catch(e => {
      setErrorMessage("Link is Invalid or Expired!");
    }).finally(() => {
      setLoading(false);
    });
  }, []);

  return (
    <>
      <ToastContainer />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            {isLoading ? <LoadingSpinner /> : !!errorMessage ? <InvalidLinkMessage /> : <Routes />}
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;
